// Nav Scroll

const nav = document.querySelector('nav');

window.addEventListener('scroll', () => {
  if (window.scrollY >= 120) {
    nav.classList.add('scroll');
  } else {
    nav.classList.remove('scroll');
  }
});

// Toggle Grid

const grid = document.querySelector('section.overlay-grid');
const gridToggle = document.querySelector('div.grid-toggle');

function gridToggleClick() {
  if (grid.classList.contains('hidden')) {
    grid.classList.remove('hidden');
    grid.classList.add('block');
    gridToggle.classList.add('checked');
  } else {
    grid.classList.remove('block');
    grid.classList.add('hidden');
    gridToggle.classList.remove('checked');
  }
}

gridToggle.addEventListener('click', gridToggleClick);

// Parallax

const Rellax = require('rellax');

const rellax = new Rellax('.rellax', {
  center: true,
});

// CTA Hover

const allCTAs = document.querySelectorAll('.cta-container');

allCTAs.forEach(cta => {
  const ctaButton = cta.querySelector('.cta-button');
  const ctaOverlay = cta.querySelector('.cta-overlay');

  function buttonEnterCallback() {
    ctaOverlay.classList.add('zoom');
  }

  function buttonLeaveCallback() {
    ctaOverlay.classList.remove('zoom');
  }

  ctaButton.addEventListener('mouseenter', buttonEnterCallback);
  ctaButton.addEventListener('mouseleave', buttonLeaveCallback);
});

// Fade in animation

const elFade = document.querySelectorAll('.watch-fade');

// Intersection Observer Options
const offsetTop = `0%`;
const offsetBot = `-25%`;
const observerOptions = {
  rootMargin: `${offsetTop} 0px ${offsetBot} 0px`,
  threshold: [0],
};

// if element is intersecting and intersection ratio is >= 0, label as in view
// if element is not intersecting and intersection ratio is >= 0, label as outView
// if element is not intersecting and intersection ratio is < 0, do nothing

// Intersection Observer Callback Function
function observerCallback(entries) {
  entries.forEach(entry => {
    console.log(entry.boundingClientRect.top);
    if (entry.isIntersecting && entry.boundingClientRect.top >= 0) {
      console.log(`Hello!`);
      entry.target.classList.add('inView');
      entry.target.classList.remove('outView');
    } else if (!entry.isIntersecting && entry.boundingClientRect.top >= 0) {
      console.log(`Nope!`);
      entry.target.classList.remove('inView');
      entry.target.classList.add('outView');
    } else {
      null;
    }
  });
}

// Instancing a New Intersection Observer
const observer = new IntersectionObserver(observerCallback, observerOptions);

elFade.forEach(obj => {
  observer.observe(obj);
});
